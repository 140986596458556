import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "leading-8 md:leading-6 text-justify mb-6 font-nunito-sans"
}
const _hoisted_2 = { class: "pb-6" }
const _hoisted_3 = { class: "flex w-full border-b-2 border-color-gray-400" }
const _hoisted_4 = { class: "flex-auto text-left font-bold" }
const _hoisted_5 = { class: "hidden md:inline flex-auto text-right text-sm font-semibold" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "relative flex flex-grow-0 flex-wrap py-1 px-0 mt-8 text-gray-900 font-semibold text-xs leading-snug gap-x-1 gap-y-2" }
const _hoisted_8 = {
  key: 0,
  class: "relative flex flex-grow-0 flex-wrap py-1 px-0 mt-8 text-gray-900 font-semibold text-xs leading-snug gap-x-1 gap-y-2"
}
const _hoisted_9 = { class: "flex flex-row justify-around gap-4 flex-wrap md:flex-nowrap" }
const _hoisted_10 = ["href"]
const _hoisted_11 = ["src", "alt"]
const _hoisted_12 = { class: "text-center" }

import { onDeactivated, onMounted, ref } from "vue";
import ArticleService from "@/services/ArticleService";
import Article from "@/types/Article";
import ResponseData from "@/types/ResponseData";
import SingleArticlePageTagItem from "@/components/SingleArticlePageTagItem.vue";
import LogFeErrorsService from "@/services/log-fe-errors-service";
import { useRoute, useRouter } from "vue-router";

const disclaimer = `
  <div class="pt-4">
  If you liked the article, feel free to share it with your friends, family, or colleagues.
  You can also follow me on
  <a href="https://medium.com/@pooyan_razian" class="link" target="_blank">Medium</a> or
  <a href="https://www.linkedin.com/in/prazian/" class="link" target="_blank">LinkedIn</a>.
  </div>
  <h3 class="header-sub-3">Copyright & Disclaimer</h3>
  <div class="pt-4">
    <ul class="list-disc list-inside pt-2">
      <li class="pl-2 pt-2">
        All content provided on this blog is for informational purposes only. The owner of this blog makes no
        representations as to the accuracy or completeness of any information on this site or found by following any
        link on this site.
      </li>
      <li class="pl-2 pt-2">
        All the content is copyrighted and may not be reproduced on other websites, blogs, or social media.
        You are not allowed to reproduce, summarize to create derivative work, or use any content from this website
        under your name. This includes creating a similar article or summary based on AI/GenAI.
        For educational purposes, you may refer to parts of the content, and only refer, but you must provide a link
        back to the original article on this website. This is allowed only if your content is less than 10% similar to
        the original article.
      </li>
      <li class="pl-2 pt-2">
        While every care has been taken to ensure the accuracy of the content of this website, I make no representation
        as to the accuracy, correctness, or fitness for any purpose of the site content, nor do I accept any liability
        for loss or damage (including consequential loss or damage), however, caused, which may be incurred by any
        person or organization from reliance on or use of information on this site.
      </li>
      <li class="pl-2 pt-2">
        The contents of this article should not be construed as legal advice.
      </li>
      <li class="pl-2 pt-2">
        Opinions are my own and not the views of my employer.
      </li>
      <li class="pl-2 pt-2">
        English is not my mother-tongue language, so even though I try my best to express myself correctly, there might
        be a chance of miscommunication.
      </li>
      <li class="pl-2 pt-2">
        Links or references to other websites, including the use of information from 3rd-parties, are provided for the
        benefit of people who use this website. I am not responsible for the accuracy of the content on the websites
        that I have put a link to and I do not endorse any of those organizations or their contents.
      </li>
      <li class="pl-2 pt-2">
        If you have any queries or if you believe any information on this article is inaccurate, or if you think any of
        the assets used in this article are in violation of copyright, please <a href="https://pooyan.info/contact" class="link">contact me</a>
        and let me know.
      </li>
    </ul>
  </div>
  `;


export default /*@__PURE__*/_defineComponent({
  __name: 'Article',
  setup(__props) {

const router = useRouter();

const article = ref<Article>({
  title: "",
  content: "",
  publishedAt: "",
  tags: [],
  mainImage: "",
  slug: "",
});
const relatedArticles = ref<Article[]>([]);

const dateOnly = (dateString: string) => {
  if (!dateString) {
    return "";
  }
  const date = new Date(dateString);
  return (
    date.getDate() + " / " + (date.getMonth() + 1) + " / " + date.getFullYear()
  );
};

const retrieve = (slug: string) => {
  ArticleService.get(slug)
    .then((response: ResponseData) => {
      article.value = response.data.data;
      const summary =
        article.value?.content
          ?.replace(/<\/?[^>]+(>|$)/g, "")
          ?.substring(0, 100) + " ...";
      document.title = "Pooyan - " + article.value.title || "Pooyan - article";
      document
        ?.querySelector('meta[property="og:title"]')
        ?.setAttribute("content", article.value.title);
      document
        ?.querySelector('meta[property="og:description"]')
        ?.setAttribute("content", summary);
      if (article.value.mainImage) {
        document
          ?.querySelector('meta[property="og:image"]')
          ?.setAttribute("content", article.value.mainImage);
      }
      // Append disclaimer to the end of the article
      if (article.value.content) {
        article.value.content += disclaimer;
      }
    })
    .catch((e: Error) => {
      // pathFrom is the referrer page (get it from the window object)
      const pathFrom = document.referrer;
      const pathTo = window.location.href;
      LogFeErrorsService.log({
        errorCode: 404,
        errorMessage: e.message,
        pathFrom: pathFrom,
        pathTo: pathTo,
      }).then(() => {
        router.push({ name: "404" });
      });
    });
};

const getRelatedArticles = (slug: string) => {
  ArticleService.getRelated(slug).then((response: ResponseData) => {
    relatedArticles.value = response.data.data;
  });
};

const routeToArticle = (slug: string) => {
  const router = useRouter();
  router
    .push({ name: "Article", params: { slug: slug } })
    // To fix the issue the contents of the page were not re-rendered
    .then(() => {
      window.location.reload();
    });
};

onMounted(() => {
  const route = useRoute();
  retrieve(route.params.slug.toString());
  getRelatedArticles(route.params.slug.toString());
});
onDeactivated(() => {
  document.title = "Pooyan Razian";
  document
    ?.querySelector('meta[property="og:title"]')
    ?.setAttribute("content", "Pooyan Razian");
  document
    ?.querySelector('meta[property="og:description"]')
    ?.setAttribute(
      "content",
      "Pooyan Razian's Personal portfolio. A full-stack software developer highly experienced in cloud infrastructure."
    );
  document
    ?.querySelector('meta[property="og:image"]')
    ?.setAttribute("content", "https://pooyan.info/img/pooyan.png");
});

return (_ctx: any,_cache: any) => {
  return (article.value && article.value.title)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(article.value.title), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(dateOnly(article.value.publishedAt)), 1)
          ]),
          _createElementVNode("div", {
            class: "pt-8",
            innerHTML: article.value.content
          }, null, 8, _hoisted_6),
          _createElementVNode("div", _hoisted_7, [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "text-gray-900 dark:text-gray-200 px-4 py-2 gap-y-2 mx-1 whitespace-nowrap" }, "Tags: ", -1)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(article.value.tags, (tag, index) => {
              return (_openBlock(), _createBlock(SingleArticlePageTagItem, {
                key: index,
                tag: tag.toLowerCase()
              }, null, 8, ["tag"]))
            }), 128))
          ]),
          (relatedArticles.value.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _cache[2] || (_cache[2] = _createElementVNode("span", { class: "text-gray-900 dark:text-gray-200 px-4 py-2 gap-y-2 mx-1 whitespace-nowrap" }, "Related Articles: ", -1)),
                _createElementVNode("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(relatedArticles.value, (relatedArticle, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                      _createElementVNode("a", {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (routeToArticle(article.value.slug))),
                        href: 'https://pooyan.info/articles/' + relatedArticle.slug,
                        class: "block cursor-pointer"
                      }, [
                        _createElementVNode("img", {
                          src: relatedArticle.mainImage,
                          class: "img-rounded-full max-h-56",
                          alt: relatedArticle.title
                        }, null, 8, _hoisted_11),
                        _createElementVNode("div", _hoisted_12, _toDisplayString(relatedArticle.title), 1)
                      ], 8, _hoisted_10)
                    ]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})